import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import Image from "gatsby-image/withIEPolyfill"
import { useStaticQuery, graphql, Link } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { eq: "CedilarMapa.jpg" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const mapImg = data?.allFile?.edges[0]?.node?.childImageSharp?.fluid || null

  return (
    <footer className="py-2 pt-8 bg-gray-900 px-4 md:px-0">
      <div className="container grid grid-cols-1 md:grid-cols-3 gap-4 mx-auto">
        <div>
          <span className="text-xl text-white">Menu</span>
          <ul className="mt-2">
            <li className="text-white">
              <Link to="/" aria-label="Ir a Página Inicial">
                Início
              </Link>
            </li>
            <li className="text-white">
              <Link to="/servicos" aria-label="Ir a Página de Serviços">
                Serviços
              </Link>
            </li>
            <li className="text-white">
              <Link to="/sobre" aria-label="Ir a Página Sobre Nós">
                Sobre
              </Link>
            </li>
            <li className="text-white">
              <Link to="/autorizadas" aria-label="Ir a Página de Autorizadas">
                Autorizadas
              </Link>
            </li>
            <li className="text-white">
              <Link to="/contato" aria-label="Ir a Página de Contato">
                Contato
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <div className="flex text-white mb-2">
            <svg
              className="fill-current h-8 w-8 mr-2"
              width="60"
              height="60"
              viewBox="0 0 54 54"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
            </svg>
            <span className="font-semibold text-2xl tracking-tight">
              Cedilar
            </span>
          </div>
          <span className="text-white">Assistência Técnica Porto Alegre</span>
          <br />
          <span className="text-sm text-white">
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Av. Ipiranga, 1477 -
            Azenha, Porto Alegre
          </span>
          <ul className="items-center justify-between sm:justify-end mt-1">
            <li className="mr-3 text-white text-sm">
              <a href="tel:+555132179658" aria-label="Ligar Para Cedilar">
                <FontAwesomeIcon icon={faPhone} /> +55 51 3217-9658
              </a>
            </li>
            <li className="mr-3 text-white text-sm mt-1">
              <a
                href="https://api.whatsapp.com/send?phone=555132179658"
                aria-label="Enviar Mensagem Pelo WhatsApp"
              >
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className="bg-green-600 rounded-full text-2xl md:text-sm"
                />{" "}
                +55 51 3217-9658
              </a>
            </li>
          </ul>
        </div>

        <div>
          <span className="text-xl text-white">Direções</span>
          <a
            href="https://www.google.com/maps/place/CeDilar+Technical+Assistance/@-30.0480295,-51.2167232,17z/data=!3m1!4b1!4m5!3m4!1s0x9519785a3631eabd:0x4ace6c9188c9fb67!8m2!3d-30.0480342!4d-51.2145345"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Direções por Google Map"
          >
            <Image
              className="lazyload max-w-sm mt-4"
              objectFit="contain"
              fluid={mapImg}
              alt="Direções Cedilar POA"
            />
          </a>
        </div>
      </div>

      <div className="container mx-auto text-white text-xs mt-8">
        © {new Date().getFullYear()} Cedilar
      </div>
    </footer>
  )
}

export default Footer
