import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const Topbar = () => (
  <div className="p-2 bg-gray-900">
    <div className="container flex mx-auto justify-between items-center">
      <div className="flex text-white">
        <span className="text-xs">
          <FontAwesomeIcon icon={faMapMarkerAlt} /> Av. Ipiranga, 1477 - Azenha,
          Porto Alegre
        </span>
      </div>

      <ul className="flex items-center justify-between justify-end">
        <li className="mr-6 text-white text-xs">
          <a href="tel:+555132179658" aria-label="Ligar Para Cedilar">
            <FontAwesomeIcon
              icon={faPhone}
              className="rounded-sm text-2xl md:text-sm"
            />
            <span className="hidden md:inline-block">+55 51 3217-9658</span>
          </a>
        </li>
        <li className="mr-3 text-white text-xs">
          <a
            href="https://api.whatsapp.com/send?phone=555132179658"
            aria-label="Enviar Mensagem Pelo WhatsApp"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="bg-green-600 rounded-full text-3xl md:text-sm"
            />{" "}
            <span className="hidden md:inline-block">+55 51 3217-9658</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
)

export default Topbar
