import React from "react"
import Helmet from "react-helmet"

export default React.memo = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    name: "Cedilar",
    image:
      "https://scontent.fphx1-2.fna.fbcdn.net/v/t1.0-1/p480x480/91919391_100236104993382_7346967165297229824_n.jpg?_nc_cat=104&_nc_sid=dbb9e7&_nc_ohc=z00jr6nMp8MAX9OwnX5&_nc_ht=scontent.fphx1-2.fna&_nc_tp=6&oh=429bdd55668ddcfe5986c6a6cd32886a&oe=5EB669E5",
    "@id": "",
    url: "https://cedilar.com/",
    telephone: "(51) 3217-9658",
    priceRange: "$",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Av. Ipiranga, 1477 - Azenha",
      addressLocality: "Porto Alegre",
      postalCode: "90160-093",
      addressCountry: "BR",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: -30.048029,
      longitude: -51.21449759999999,
    },
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "09:00",
        closes: "12:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "14:00",
        closes: "18:00",
      },
    ],
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      reviewCount: "4",
    },
    review: [
      {
        "@context": "http://schema.org/",
        "@type": "Review",
        itemReviewed: {
          "@type": "Thing",
          name: "Conserto de Barbeador",
        },
        author: {
          "@type": "Person",
          name: "Marcos Hackman",
        },
        datePublished: "2019-09-12",
        reviewRating: {
          "@type": "Rating",
          description:
            "Meu barbeador parou de funcionar e decidi levar para uma assistência técnica pois acredito que ainda posso usar esse aparelho por mais alguns anos. A Cedilar fez um serviço rápido e por um preço just. Recomendo o serviço deles.",
          ratingValue: "5",
        },
      },
      {
        "@context": "http://schema.org/",
        "@type": "Review",
        itemReviewed: {
          "@type": "Thing",
          name: "Chuveiro Hydra",
        },
        author: {
          "@type": "Person",
          name: "Helena Lopes",
        },
        datePublished: "2018-04-22",
        reviewRating: {
          "@type": "Rating",
          description:
            "A Cedilar foi recomendada pela Hydra por ser uma das poucas autorizadas de Porto Alegre. Serviço bem feito e rápido. Muito obrigada!",
          ratingValue: "5",
        },
      },
      {
        "@context": "http://schema.org/",
        "@type": "Review",
        itemReviewed: {
          "@type": "Thing",
          name: "Air Fryer",
        },
        author: {
          "@type": "Person",
          name: "Fernando Marin",
        },
        datePublished: "2020-01-17",
        reviewRating: {
          "@type": "Rating",
          description:
            "Usei os serviços de limpeza de air fryer da Cedilar e fiquei muito satisfeito! Está funcionando como se fosse nova.",
          ratingValue: "5",
        },
      },
      {
        "@context": "http://schema.org/",
        "@type": "Review",
        itemReviewed: {
          "@type": "Thing",
          name: "Chuveiro da Cardal",
        },
        author: {
          "@type": "Person",
          name: "Márcio Alves",
        },
        datePublished: "2019-11-03",
        reviewRating: {
          "@type": "Rating",
          description:
            "A Cardal recomendou a Cedilar para fazer serviço no nosso chuveiro já que está dentro da garantia. Bom atendimento e o rapaz foi bem atencioso.",
          ratingValue: "5",
        },
      },
    ],
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}
