import React from "react"
import { Link } from "gatsby"
import MobileMenu from "./mobile-menu"

import PropTypes from "prop-types"

const Header = () => {
  return (
    <header className="p-4 bg-blue-600">
      <div className="container flex mx-auto justify-between">
        <Link
          to="/"
          className="flex text-white"
          aria-label="Ir a Página Inicial"
        >
          <svg
            className="fill-current h-8 w-8 mr-2"
            width="60"
            height="60"
            viewBox="0 0 54 54"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
          </svg>
          <span className="font-semibold text-2xl tracking-tight">Cedilar</span>
        </Link>

        <ul className="hidden sm:flex items-center">
          <li className="mr-3 text-white">
            <Link to="/" aria-label="Ir a Página Inicial">
              Início
            </Link>
          </li>
          <li className="mr-3 text-white">
            <Link to="/servicos" aria-label="Ir a Página de Serviços">
              Serviços
            </Link>
          </li>
          <li className="mr-3 text-white">
            <Link to="/sobre" aria-label="Ir a Página Sobre Nós">
              Sobre
            </Link>
          </li>
          <li className="mr-3 text-white">
            <Link to="/autorizadas" aria-label="Ir a Página de Autorizadas">
              Autorizadas
            </Link>
          </li>
          <li className="mr-3 text-white">
            <Link to="/contato" aria-label="Ir a Página de Contato">
              Contato
            </Link>
          </li>
        </ul>
        <div className="sm:hidden">
          <MobileMenu
            right
            pageWrapId={"gatsby-focus-wrapper"}
            outerContainerId={"___gatsby"}
          />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
