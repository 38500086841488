import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const MobileMenu = props => {
  return (
    <Menu {...props}>
      <ul className="items-center">
        <li className="mb-2 text-white">
          <Link to="/" aria-label="Ir a Página Inicial">
            Início
          </Link>
        </li>
        <li className="mb-2 text-white">
          <Link to="/servicos" aria-label="Ir a Página de Serviços">
            Serviços
          </Link>
        </li>
        <li className="mb-2 text-white">
          <Link to="/sobre" aria-label="Ir a Página Sobre Nós">
            Sobre
          </Link>
        </li>
        <li className="mb-2 text-white">
          <Link to="/autorizadas" aria-label="Ir a Página de Autorizadas">
            Autorizadas
          </Link>
        </li>
        <li className="mb-24 text-white">
          <Link to="/contato" aria-label="Ir a Página de Contato">
            Contato
          </Link>
        </li>
        <li className="mb-2 text-white mb-10">
          <Link to="/contato" aria-label="Ir a Página de Contato">
            <FontAwesomeIcon icon={faPhone} /> Fone: <br />
            <span className="text-base">+55 51 3217-9658</span>
          </Link>
        </li>
        <li className="mb-2 text-white mb-10">
          <Link to="/contato/" aria-label="Ir a Página de Contato">
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="bg-green-600 rounded-sm text-sm"
            />{" "}
            WhatsApp: <br />
            <span className="text-base">+55 51 3217-9658</span>
          </Link>
        </li>
        <li className="mb-2 text-white">
          <Link to="/contato" aria-label="Ir a Página de Contato">
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Endereço: <br />
            <span className="text-base">
              Av. Ipiranga, 1477 - Azenha,
              <br />
              Porto Alegre - RS
            </span>
          </Link>
        </li>
      </ul>
    </Menu>
  )
}

export default MobileMenu
