import React from "react"
import PropTypes from "prop-types"

import Topbar from "./topbar"
import Header from "./header"
import Hero from "./hero"
import Footer from "./footer"

import "../styles/tailwind.css"

const Layout = ({ children, showHero = false }) => {
  return (
    <>
      <Topbar />
      <Header />
      {showHero && <Hero />}
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
