import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: allFile(filter: { relativePath: { eq: "HomeHeroBg.jpg" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <div className="md:hidden bg-gray-200">
        <Image
          fluid={data?.heroBg?.edges[0]?.node?.childImageSharp?.fluid}
          alt="Cedilar Porto Alegre-RS"
        />

        <h3 className="text-base font-bold px-4 pt-4">
          &#10003; Serviços Dentro e Fora da Garantia
        </h3>
        <h3 className="text-base font-bold px-4">
          &#10003; Acessórios e Peças Originais
        </h3>
        <h3 className="text-base font-bold px-4">
          &#10003; Atendimento Rápido Pelo WhatsApp Business
        </h3>
        <h3 className="text-base font-bold  px-4 pb-4">
          &#10003; Envie Uma Mensagem!
        </h3>
      </div>
      <BackgroundImage
        Tag="section"
        fluid={data?.heroBg?.edges[0]?.node?.childImageSharp?.fluid}
        className="hidden md:block pb-1/5 md:pb-1/4 bg-cover"
        alt="Cedilar Porto Alegre-RS"
      >
        <div>
          <h3 className="text-lg md:text-xl lg:text-3xl font-bold text-center">
            &#10003; Serviços Dentro E Fora da Garantia
          </h3>
          <h3 className="text-lg md:text-xl lg:text-3xl font-bold text-center">
            &#10003; Acessórios e PeçasOriginais
          </h3>
          <h3 className="text-lg md:text-xl lg:text-3xl font-bold text-center">
            &#10003; Atendimento Rápido Pelo WhatsApp Business
          </h3>
        </div>
      </BackgroundImage>
    </>
  )
}

export default Hero
